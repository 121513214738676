@charset "UTF-8";
@import "../../fonts/icomoon/style";

@font-face {
  font-display: swap;
  font-family: Hlv-reg;
  font-style: normal;
  font-weight: 400;
  src: local("Hlv-reg"),
    url(/fonts/HelveticaNeueCE55Roman.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Hlv-bold;
    font-style: normal;
    font-weight: 400;
    src: local("Hlv-bold"),
      url(/fonts/HelveticaNeueCE75Bold.woff2) format("woff2");
}

@font-face {
    font-display: swap;
    font-family: Hlv-thin;
    font-style: normal;
    font-weight: 400;
    src: local("Hlv-thin"),
      url(/fonts/HelveticaNeueCE35Thin.woff2) format("woff2");
}
