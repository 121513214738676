.wysiwyg-output{
    @media screen and (max-width: $tablet){
        & > .h1, & > h1, h1,
        & > .h2, & > h2, h2{
            margin: 0 0 20px !important;
        }

        & > .h4, & > h4, h4 {
            font-size: 17px !important;
            margin: 0 0 20px !important;
        }
    }

    .fr-img-wrap {
        position: static; /* relative; */

        .fr-inner {
            display: initial; /* block;  */
            font-size: inherit; /* 12px; */
            padding-bottom: 0; /* 15px; */
        }
    }

    p {
        @media only screen and (max-width: $small){
            margin: o !important; /* 15px 0 !important; */
        }      
    }

    & > a.button--dark,
    & > p > a.button--dark,
    & > a.button--white,
    & > p > a.button--white,
    & > a.button--border,
    & > p > a.button--border {
        padding: 18px 30px 17px;
        vertical-align: baseline; /* top; */
        line-break: inherit; /* normal; */
        white-space: inherit;
    }

    table {
        margin: 30px 15px;
    }

    .table-warning {
        position: relative;
        background: $white;
        border-radius: 3px;
        color: $orange;
        overflow: unset;
        border: 3px solid $orange;
        -webkit-box-shadow: 0 50px 80px rgba(65, 93, 125, 0.1);
        box-shadow: 0 50px 80px rgba(65, 93, 125, 0.1);

        @media only screen and (max-width: $mobile-edge){
            margin: 0 !important;
        }

        &:before {
            position: absolute;
            content: "";
            top: -50px;
            left: 50%;
            width: 90px;
            height: 90px;
            border-radius: 50px;
            padding: 10px;
            color: $white;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            -webkit-box-shadow: 0 4px 6px rgba($black, 0.11);
            box-shadow: 0 4px 6px rgba(rgba($black, 0.11), 0.11);
        }

        tr:nth-child(2n),
        tr:nth-child(odd) {
            background: transparent;
        }

        td,
        th {
            position: relative;
            padding: 85px 35px 70px;
            vertical-align: top;
            text-align: left;
            border: 0;
            color: $orange;

            @media screen and (max-width: $tablet) {
                  display: block !important;
                  width: 100% !important;
            
            }

            a, h1, h2, h3, h4, h5, h6, p, span {
                color: $orange!important;
                color: inherit !important;
            }

            a {
                text-decoration: none;
            }

        }
    }

    .table-no-border{
        td, th{
            @media only screen and (max-width: $small){
                padding: 10px;
            }
        }
    }

    .table-half-width {
        td{
            @media only screen and (max-width: $mobile-edge){
                padding: 0;
            }
        }
    }

    .oc-quotation,
    blockquote {
        padding-left: 0;
        border-left: 0 none;
    }

    .faq--wrapper{
        @media only screen and (max-width: $desktop-M){
            padding: 0 !important;
        }
    }

    .bestsellers{

        &.products-slider-snippet {
            background: transparent;
            margin: 0;
            overflow: visible;
            border-left: 0 !important;
            border-right: 0 !important;

            &:after,
            &:before{
                display: initial !important; /* none !important; */
            }

            @media only screen and (max-width: $small){
                padding: 0 !important;
                margin: 0;
            }
        }
    }
}