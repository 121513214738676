.vzorky-modal {
  position: fixed;
  padding: 20px;
  z-index: 9999;
  background: #fff;
  overflow: auto;
  outline: none;

  .button--dark,
  .button--border {
    padding:5px 60px;
    min-height: 55px;
    font-size: $font-size--medium;
  }
}

.vzorky-modal__title {
  font-size: $font-size--large;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
