.form-save-ziadanka {
  position: relative;
}

.form-save-ziadanka__overlay {
  position: absolute;
  inset: 0;
  z-index: 1;
  background: rgba(255,255,255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
