// colors

$white:         #fff;
$black:         #111;
$light-gray:    #dce4ea;
$orange:        #f97316;

// media screen

$desktop-XL:    1360px;
$desktop-L:     1290px;
$desktop-M:     1250px;
$desktop:       1200px;
$tablet:        992px;
$tablet-edge:   991.98px;
$mobile-edge:   768.98px;
$mobile:        768px;
$small:         600px;
$display-sx:    350px;

$font-size--medium: 16px;
$font-size--large: 24px;
