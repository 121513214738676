@import 'variables';
@import 'helpers/spacing';
@import 'parts/fonts';
@import 'parts/main';
@import 'parts/form';
@import 'parts/wysiwyg';
@import 'parts/post';
@import 'parts/price-box';
@import "parts/vzorky";
@import "parts/vzorky-modal";
@import "parts/modal";
@import "parts/form-save-ziadanka";
@import "parts/table-component";

.sex-item,
.testing-item{

    &.active{
        border: 1px solid $light-gray;
        background-color: $white;
    }

    input[type="radio"] + .cr > .cr-icon{
        background: url(/images/forms/radio-unchecked.png) no-repeat 0 0;
    }

    input[type="radio"]:checked + .cr > .cr-icon {
        background: url(/images/forms/radio-checked.png) no-repeat 0 0;
    }

    .sex-icon.woman {
        background: url(/images/forms/woman.png) no-repeat 0 0;
    }

    .sex-icon.man {
        background: url(/images/forms/man.png) no-repeat 0 0;
    }
}

.benefits li:before {
    background: url(/images/forms/check-blue.png) no-repeat 50%;
}

.questions-boxes .question-box .in .text-wrapper{
    ol li:before,
    ul li:before {
        background: url(/images/forms/check-blue.png) no-repeat 50%;
    }
}

.cta-wrapper{
    .cta-img {
        position: absolute;
        bottom: 0;
        right: 0;

        @media screen and (max-width: $desktop-M){
            position: relative;
            max-width: 100%;
            height: auto;
            bottom: unset;
            right: auto;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }

        @media screen and (max-width: $small){
            position: relative;
            width: 100%;
            height: 100%;
            bottom: unset;
            right: 0;
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
            order: 1;
        }
    }
}

.testimonial .testimonial--slider .testimonial--item .in .person .avatar {
    background-image: url(/images/avatar-muz.svg);
}

.logged-in-user {

    .logged-in-actions .user-actions a .sub-header-icon {
        width: 15px;
    }

    &-info .avatar {
        background: url(/images/avatar-muz.svg) 50% no-repeat;
    }
}

.header-cart--menu .header-cart--menu--steps .ul-main > li > a {
    font-weight: 700;

    @media only screen and (max-width: $desktop-XL){
        max-width: 90px;
    }

    .visited-icon {
        background: #58acfa url(/images/forms/check-white.png) no-repeat 50%;
        flex: none;
    }
}

.checkout{
    @media screen and (max-width: $desktop-L){
        background-color: #fff;
    }

    .checkout--box{

        .checkout--box-inner{
            display: block;
            padding-bottom: 40px;
            padding-top: 80px;

            @media screen and (max-width: $desktop-L){
                padding-top: 40px;
            }

            .form-info .benefits {
                margin: 15px 0 30px;
                padding: 0;
                list-style: none;

                li {
                    position: relative;
                    padding: 10px 0 10px 35px;
                    color: #213353;
                    font-family: Hlv-reg, Helvetica, serif !important;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 30px;

                    &:before {
                        position: absolute;
                        content: "";
                        top: 17px;
                        left: 0;
                        width: 12px;
                        height: 13px;
                        background: url(/images/forms/check-blue.png) no-repeat 50%;
                      }
                }
            }
        }

        .column {
            padding-top: 0;
            padding-bottom: 0;

            @media screen and (max-width: $desktop-L){
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .box {
            margin: 0;

            h2 {
                margin-top: 0;
            }

            .box-headline {
                font-family: Hlv-bold, Helvetica, serif !important;
                font-size: 48px;
            }
        }
    }
}

.payment-box .payment-item input[type="radio"]{

    & + .cr > .cr-icon {
        background: url(/images/forms/radio-unchecked.png) no-repeat 0 0;
    }

    &:checked + .cr > .cr-icon {
        background: url(/images/forms/radio-checked.png) no-repeat 0 0;
    }
}

.contact__faq .white-faq {
    float: none !important; /* unset !important; */
}

.sectors .sectors--boxes li a .sector-icon {
    background-size: auto; /* contain; */
    background-position: 0% 0%; /* 0 0; */
    background-repeat: repeat; /* no-repeat; */
}

.password-reset__form-container {
    @media only screen and (min-width: 599.9px){
        padding: 150px 0 0;
    }
}

.sub-header-icon {
    height: 16px;

    &.icon-0 {
        width: 14px;
    }

    &.icon-1 {
        width: 16px;
    }

    &.icon-2 {
        width: 12px;
    }
}

.user-dashboard--wrapper
  .profile-info-progress
  .progress-info
  .infos
  .progress-indicators
  .progress-indicator{

    img {
        width: auto; /* 20px; */
        margin-top: 0; /* -2px; */
      }

      p {
        color: #213353;
        font-family: Hlv-reg, Helvetica, serif !important;
        font-weight: 400;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        margin: 0 0 0 12px;
      }
}

.text-height-0 {
    line-height: 0;
}

.modal {
    position: fixed;
    inset: 0;
    z-index: 9999 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.6);
}

.button-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%,-50%);
}

.table {
    .btn {
        &.button--dark,
            &.button--white {
            border-radius: 0;
        }

        &.button--dark {
            background-color: #58acfa;

            .icon {
                color: #fff;
            }
        }

        &.button--white {
            border: 0;

            .icon {
                color: #58acfa;
            }

            &:hover {
                .icon {
                    color: #fff;
                }
            }
        }
    }
}
