.modal {
  z-index: 9999;
}

.modal__dialog {
  position: fixed;
  padding: 20px;
  background: #fff;
  overflow: auto;
  outline: none;
  min-width: 300px;
  max-width: 400px;
  box-shadow: 0 5px 15px rgba(0,0,0, .2);
}

// React-modal transitions
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
