.custom-dropdown {
    position: relative;
    display: inline-block;
}

.custom-dropdown-menu {
    background-color: white;
    position: absolute;
    width: 100%;
    /*max-width: 90vh;*/
    height: auto;
    max-height: 50vh;
    overflow-x: hidden;
    box-shadow: inset 0 0 13px 0 rgba(0, 0, 0, 0.13);
    -webkit-transition: border 0.3s ease;
    transition: border 0.3s ease;
    z-index: 5;
    border: 1px solid #ddd;
    margin: 0;
    padding: 0;
}

.custom-dropdown-item {
    min-width: inherit;
    list-style-type: none;
    padding: 12px;
    margin: 0;
    cursor: pointer;
}

.custom-dropdown-item:hover{
    background-color: #ebebeb;
}

/* link sme zrusili a tento overridol footer created transparent a color 
.custom-dropdown-item a, a:hover, a:link, a:visited{
    color: inherit;
    text-decoration: none;
} */

.custom-dropdown-item span {
    font-size: 13px;
    font-weight: bold;
}

.custom-dropdown-item p {
    font-size: 13px;
    font-weight: normal;
}
