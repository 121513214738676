.App {
/*  text-align: center */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-content {
  display: block;
  opacity : 1;
}

.rmsc .dropdown-content {
  z-index:999;
}

.form-group .select-item {
  margin-top: 2px!important;
  margin-bottom: 1px!important;
}

.select-item > .item-renderer > span{
  font-size:0.7rem!important;
}

.ReactTags__remove {
  margin: 2px 12px 2px 4px ;
}

li.is-active {
  color:white;
  border: 1px solid black;
  background-color: grey;
}
.error {
  color: red;
  font-size: smaller;
}

.row-dummy {
  min-height: 10px;
}

.modal-send {
  border: 1px solid blue;
}
