.post{
    &--detail{
        .post-header {
            padding: 120px 0 60px;

            @media only screen and (max-width: $desktop){
                padding: 40px 25px;
            }

            &:before {
                content: " ";
                position: absolute;
                left: 0;
                top: 0;
                background-color: $white;
                background-image: -webkit-gradient(
                  linear,
                  left top,
                  right bottom,
                  from(#eaeff6),
                  color-stop(#eef3f8),
                  color-stop(#e9eef6),
                  color-stop(#e3eaf3),
                  to(#dee5f1)
                );
                background-image: linear-gradient(
                  to right bottom,
                  #eaeff6,
                  #eef3f8,
                  #e9eef6,
                  #e3eaf3,
                  #dee5f1
                );
                width: 100%;
                height: 620px;
                z-index: 1;

                @media only screen and (max-width: $small){
                    height: 515px;
                }
            }

            .item-image {
                max-width: 1170px;
            }

            .item-info {
                max-width: 770px;

                .info {
                    position: static;

                    .category {
                        margin-right: 20px;
                        padding: 9px 16px;
                        font-size: 10px;
                      }
                }

                .title {
                    padding-top: 15px;
                    font-size: 48px;
                    line-height: 60px;
                    margin: 0 0 70px;
                  }
            }
        }
        
        .post-content {
            padding: 30px 0 120px;

            @media only screen and (max-width: $desktop){
                padding: 30px 25px 100px;
            }

            a {
                white-space: inherit;
            }

            .inner {
                max-width: 770px;

                @media only screen and (max-width: $desktop){
                    margin: 0;
                }
            }
        }
    } 

    &--item .in .item-info .info .date {
        color: #738498;
        font-size: 14px;
        letter-spacing: inherit; /* 0.91px; default:normal */
        text-transform: inherit; /* uppercase; default:none */

        @media only screen and (max-width: $mobile){
            margin-top: 10px;
        }
    }
}