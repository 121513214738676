@for $i from 1 through 7 {
  .sf-m-#{$i},
  .sf-my-#{$i},
  .sf-mt-#{$i} {
    margin-top: 10px * $i !important;
  }

  .sf-m-#{$i},
  .sf-mx-#{$i},
  .sf-mr-#{$i} {
    margin-right: 10px * $i !important;
  }

  .sf-m-#{$i},
  .sf-my-#{$i},
  .sf-mb-#{$i} {
    margin-bottom: 10px * $i !important;
  }

  .sf-m-#{$i},
  .sf-mx-#{$i},
  .sf-ml-#{$i} {
    margin-left: 10px * $i !important;
  }

  .sf-p-#{$i},
  .sf-py-#{$i},
  .sf-pt-#{$i} {
    padding-top: 10px * $i !important;
  }

  .sf-p-#{$i},
  .sf-px-#{$i},
  .sf-pr-#{$i} {
    padding-right: 10px * $i !important;
  }

  .sf-p-#{$i},
  .sf-py-#{$i},
  .sf-pb-#{$i} {
    padding-bottom: 10px * $i !important;
  }

  .sf-p-#{$i},
  .sf-px-#{$i},
  .sf-pl-#{$i} {
    padding-left: 10px * $i !important;
  }
}
