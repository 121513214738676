.header--menu {
    height: 90px;

    @media screen and (max-width: $desktop-M){
        height: 90px;
    }

    &--right{

        @media screen and (max-width: $desktop-M){
            overflow-y: hidden;
        }

        .ul-main > li .client-zone-btn{
            @media only screen and (max-width: $display-sx){
                line-height: 11px !important;
            }
        }

    }

    &--spat {
        margin: 0;
        padding: 0;
        width: 100%;
    }
}

.footer{
    &--links {
        padding: 0px 0 60px; /* 120px 0 60px; minimal footer */

        .footer-logos {
            margin-top: 75px; /* 140px; minimal footer */
        }
    }

    &--bottom--in {
        max-height: 60px;
    }
}

.column{
    &.white-bg{
        &:before{
            top: -80px;

            @media screen and (max-width: $desktop-L){
                top: -40px;
            }
        }
    }
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .checkout .checkout--box .checkout--box-inner .form-info .benefits li {
      font-family: Helvetica, sans-serif;
      font-weight: 400;
    }
}

.opac0 {
    opacity: 0;
}

.opac1 {
    opacity: 1;
}

.bgcTM {
    background-color: #181f2b;
}

.bgcTB {
    background-color: #f6f6f6;
}

.cBlk {
    color: black;
}

.skry {
    display: none;
}

.s {
    font-size: small;
}

.xxs {
    font-size: xx-small;
}

.p10px {
    padding: 10px;
}

.pl15px {
    padding-left: 15px;
}

.oznac-vzor,
.oznac-vzor-just {
    padding: 20px;
    background: white;
    font-size: small;
}

.oznac-vzor-just {
    text-align: justify;
}

.input-vysetrenia {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.wspl {
    white-space: pre-line;
}
