@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ncgx1w') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ncgx1w') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ncgx1w##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-bin {
  &:before {
    content: $ico-bin;
  }
}

