.price-box{
    &__td-content{

        @media only screen and (max-width: $tablet-edge){
            display: block;
            width: 100%;
            max-width: 100%;
            padding: 32px 15px 25px !important;
        }

        @media only screen and (min-width: $tablet){

            &:first-child {
                padding-left: 65px !important;
            }

            &:last-child {
                padding-right: 65px !important;
            }
        }

        @media only screen and (max-width: $tablet-edge){
            &:last-child {
                padding-top: 0 !important;
            }
        }

        @media only screen and (min-width: $tablet){
            .subtitle .icon-image {
                margin-right: 20px;
            }
        }
    }

    &__td-price {
        border: 0 !important;
        border-left: 1px solid #f3f4f6 !important;
        padding: 55px 20px 40px !important;
    }
}