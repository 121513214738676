.form-group label:not(.inline-checkbox){

    .validation-icon{

        &.is-invalid:after {
            background: url(/images/forms/invalid.png) no-repeat 0 0;
        }
    
        &.valid:after {
            height: 16px;
            background: url(/images/forms/valid.png) no-repeat 0 0;
        }
    } 

    &.sex-item{
        margin-bottom: 10px !important;
    }
}



.radio label input[type="radio"] {

    & + .cr > .cr-icon {
        background: url(/images/forms/radio-unchecked.png) no-repeat 0 0;
    }

    &:checked + .cr > .cr-icon {
        background: url(/images/forms/radio-checked.png) no-repeat 0 0;
    }
} 

.input-box{

    &.is-invalid:after {
        background: url(/images/forms/invalid.png) no-repeat 0 0;
    }

    &.valid:after {
        background: url(/images/forms/valid.png) no-repeat 0 0;
    }
}

.user-forms .form-info .benefits li:before {
    width: 12px;
    height: 13px;
    background: url(/images/forms/check-blue.png) no-repeat 50%;
}

.form-group{
    margin-bottom: 0;

    .col-sm-12{
        &:first-child{
            margin-top: 0;
        }
    }

    .cBlk{
        margin-bottom: 10px;
    }
}