.indicator {
    line-height : 90px;
}

.checkout  {
    margin-top : 90px;
}

.header {
    height : 90px;
}

.header-cart--menu--steps {
    padding-top: 10px;
}

.header-cart--menu {
    height: 90px;
}
/* override theme.css */
.header-cart--menu .header-cart--menu--steps .ul-main > li > a {
    line-height: 20px;
    height : 80px; /* + 10px padding na header-cart--menu--steps = spolu 90px*/
}

/* Definicia .header--menu--back upravena kopia z theme.css
    pod classom  .header--menu--left, original bola pod header-cart--menu--steps
*/
.header--menu--left .header--menu--left--back {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.header--menu--left .header--menu--left--back a {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 90px;

    text-decoration: none;
    padding: 0 15px 0 15px;
    margin: 0;
    font-family: Hlv-bold, Helvetica, serif !important;
    font-style: normal;
    font-weight: 400;
    color: #fff;
    font-size: 13px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .header--menu--left .header--menu--left--back a {
        font-family: Helvetica, sans-serif;
        font-weight: 700;
    }
}
.header--menu--left .header--menu--left--back a:hover {
    background-color: rgba(48, 70, 89, 0.4);
    color: #fff;
}
.header--menu--left .header--menu--left--back a i {
    font-size: 16px;
    margin-right: 15px;
    color: #58acfa;
    line-height: 16px;
    padding: 0 0 0 2px;
}

/* transformacia pre viewport < 798px */
@media only screen and (max-width: 798px) {

    .header--menu--left .header--menu--left--back {
        width: 100%;
        height: 100%;
        padding: 0;
    }
    .header--menu--left .header--menu--left--back a {
        width: 100%;
        height: 90px;
        padding: 0 0 0 5px;
        margin: 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    /* schova nadpis "spat na web"
       nevedel som spravit aby sa oddelil link do horneho riadku
    */
    .header--menu--left .header--menu--left--back a span{
        display:none;
    }

    .header--menu {
        height: 90px;
    }

    .header--menu--left {
        height: 90px;
    }

    .header-card--menu {
        height: 90px;
    }

    .header-cart--menu .header-cart--menu--steps .ul-main > li > a{
        font-size: 10px;
        line-height: 20px;
        height:80px /* 80px + header-cart--menu--steps padding 10px = 90px */
    }

    .header-cart--menu .header-cart--menu--steps .ul-main > li > a .step-ind {
        padding-top: 4px;
        width: 35px;
        height: 35px;
    }

    .header-cart--menu .header-cart--menu--steps .ul-main > li > a .visited-icon {
        width: 35px;
        height: 35px;
    }

    /* schovaj meno doktora, ostane len avatar */
    .header--menu--right .nav-main .logged-in-user-info .user-data{
        display:none;
    }
}
