.table-component {
  table {
    color: #2e3c55;
  }

  td {
    vertical-align: middle;
  }

  th {
    font-style: normal;
    font-size: 11px;
    letter-spacing: 1px;
    line-height: 23px;
    text-transform: uppercase;
  }
}
